<template>
    <div class="mainButton">
        <button @click="emitChange()">{{this.cta}}</button>
    </div>
</template>
<script>


    export default {
    data() {
        return {
            callToAction: ""
        }
    },
    methods: {
        emitChange()
        {
            this.$emit('clicked');
        }

    },
    created()
    {
    },
    mounted()
    {

    },
    props: ['cta']
    }
</script>
<style lang="scss" scoped>
@import '../styles/variables';

.mainButton
{
    margin-top: 1.25rem;    
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button
    {
        background: none;
        font-size: 1rem;
        color: $red;
        border: 2px solid $red;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        border-radius: 5px;
        transition: all 0.4s ease-in-out;
    }

    button:hover
    {
        cursor: pointer;
        border: 2px solid $redHover;
        color: $redHover;
        transform: translateY(-1px);
        box-shadow: 0 1px 3px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.24);
    }
}

</style>