<template>
    <div class="contact">
        <navbar/>
        <div class="contact__content">
            <div class="contact__content__form">
                <div class="contact__content__form__header">
                    <span>
                        <h1>Kontakt</h1>
                    </span>
                    <span>
                        <h4>Wypełnij formularz, aby skontaktować się z obsługą sklepu.</h4>
                    </span>
                </div>
                <div class="contact__content__form__inputSection">
                    <div class="contact__content__form__inputSection__sectionName">
                        Imię Nazwisko
                    </div>
                    <input type="text" placeholder="Imię Nazwisko" v-model="message.name">
                </div>
                <transition name="fade">
                    <div class="contact__content__form__error" v-show="errors.name">
                        Sprawdź ponownie polę imię oraz nazwisko.
                    </div>
                </transition>
                <div class="contact__content__form__inputSection">
                    <div class="contact__content__form__inputSection__sectionName">
                        E-mail
                    </div>
                    <input type="text" placeholder="E-mail" v-model="message.mail">
                </div>
                <transition name="fade">
                    <div class="contact__content__form__error" v-show="errors.mail">
                        Sprawdź czy e-mail jest prawidłowy.
                    </div>
                </transition>
                <div class="contact__content__form__inputSection">
                    <div class="contact__content__form__inputSection__sectionName">
                        Telefon
                    </div>
                    <input type="text" placeholder="Telefon" v-model="message.phone">
                </div>
                <transition name="fade">
                    <div class="contact__content__form__error" v-show="errors.phone">
                        Sprawdź ponownie numer telefonu.
                    </div>
                </transition>
                <div class="contact__content__form__inputSection">
                    <div class="contact__content__form__inputSection__sectionName">
                        Tytuł
                    </div>
                    <input type="text" placeholder="Tytuł" v-model="message.topic">
                </div>
                <transition name="fade">
                    <div class="contact__content__form__error" v-show="errors.title">
                        Pole tytułu jest puste.
                    </div>
                </transition>
                <div class="contact__content__form__inputSection">
                    <div class="contact__content__form__inputSection__sectionName">
                        Treść
                    </div>
                    <textarea placeholder="Treść" v-model="message.text"></textarea>
                </div>
                <transition name="fade">
                    <div class="contact__content__form__error" v-show="errors.message">
                        Treść wiadomości jest pusta.
                    </div>
                </transition>
                <div class="contact__content__form__button">
                    <div class="contact__content__form__button__holder">
                        <button @click="validate()">Wyślij</button>
                    </div>
                    
                </div>
            </div>
        </div>
        <trust/>
        <ftr/>
    </div>
</template>
<script>
    // Imports
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";
    import mainButton from "../components/mainButton.vue";
    import axios from 'axios';
    import {
    db
    } from '../configs/firebase';

export default {
    data() {
      return {
          message:
          {
              mail: '',
              name: '',
              phone: '',
              text: '',
              topic: ''
          },
          errors:
          {
              name: false,
              mail: false,
              phone: false,
              title: false,
              message: false
          }
      }
    },
    methods:
    {
        createNewMessage()
        {
            db.collection("SupportMessages").add(this.message)
            .then(() => {
                axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/contactFormMail', this.message).catch((error)=>
                {
                    console.log(error);
                })
                this.$router.push('/podziekowanie?info=message');
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
                this.$router.push('/');
            });

        },
        validate()
        {
            if(this.message.name.length>0)
            {
                this.errors.name = false;
                if(this.message.mail.length>0 && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.message.mail))
                {
                    this.errors.mail = false;
                    if(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(this.message.phone))
                    {
                        this.errors.phone = false;
                        if(this.message.topic.length>0)
                        {
                            this.errors.title = false;
                            if(this.message.text.length>0)
                            {
                                this.errors.message = false;
                                this.createNewMessage();
                            }
                            else
                            {
                                this.errors.message = true;
                                return 0;
                            }
                        }
                        else
                        {
                            this.errors.title = true;
                            return 0;
                        } 
                    }
                    else
                    {
                        this.errors.phone = true;
                        return 0;
                    }    
                }
                else
                {
                    this.errors.mail = true;
                    return 0;
                }    
            }
            else
            {
                this.errors.name = true;
                return 0;
            }
        }
    },
    mounted()
    {
        window.scrollTo(0,0);
    },
    components: {navbar,trust,ftr,mainButton},
}
</script>
<style lang="scss" scoped>

    @import '../styles/variables';

    .fade-enter-active, .fade-leave-active {
    transition: opacity .6s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transition: opacity .3s;
    }





    h1 {
        text-align: left;
        user-select: none;
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 500;
        font-size: 1.563rem;
    }

    h3
    {
        text-align: left;
        font-weight: 500;
        padding: 0;
        font-size: 1.25rem;
        margin: 0;
    }

    h4
    {
        text-align: left;
        font-weight: 400;
        padding: 0;
        font-size: 1.25rem;
        margin: 0;
    }

    .contact
    {

        width: 100vw;


        &__content
        {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 10.527em;
            margin-bottom: 1.563rem;
            width: 100%;



            &__form
            {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 50%;

                &__error
                {
                    color: $red;
                    margin-top: 0.8rem;
                    font-size: 0.8rem;
                }

                &__header
                {
                    width: 100%;
                    margin-bottom: 1.25rem;
                }

                &__inputSection
                {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    
                    width: 100%;
                    margin-top: 1.25rem;
                    user-select: none;

                    &__sectionName
                    {
                     
                        font-weight: 500;
                    }

                    input[type="text"]
                    {
                        box-sizing: border-box;
                        padding-left: 0.8rem;
                        max-width: 100%;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        outline: none;
                        margin-top: 0.41rem;
                        font-size: 1.25rem;
                        border: 1px solid $gray;
                        border-radius: 3px;
                        font-weight: 500;
                        box-shadow: 0 1px 3px rgba(0,0,0,0.03), 0 1px 2px rgba(0,0,0,0.06);
                        opacity: 0.7;
                        transition: all 0.4s ease-in;
                    }

                    input[type="text"]::placeholder
                    {
                        color: $gray;
                        opacity: 0.4;
                        font-size: 1rem;
                    }

                    input[type="text"]:hover
                    {
                        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                        opacity: 1;
                    }

                    input[type="text"]:focus
                    {
                        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                        opacity: 1;
                    }


                    textarea
                    {
                        font-family: 'Poppins', sans-serif !important;
                        box-sizing: border-box;
                        font-weight: 1rem;
                        min-height: 100px;
                        padding-left: 0.8rem;
                        width: 100%;
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        font-weight: 400;
                        outline: none;
                        margin-top: 0.41rem;
                        font-size: 1rem;
                        border: 1px solid $gray;
                        border-radius: 3px;
                        font-weight: 500;
                        box-shadow: 0 1px 3px rgba(0,0,0,0.03), 0 1px 2px rgba(0,0,0,0.06);
                        opacity: 0.7;
                        transition: all 0.4s ease-in;
                    }

                    textarea::placeholder
                    {
                        font-family: 'Poppins', sans-serif !important;
                        color: $gray;
                        opacity: 0.4;
                        font-size: 1rem;
                    }

                    textarea:hover
                    {
                        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                        opacity: 1;
                    }

                    textarea:focus
                    {
                        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                        opacity: 1;
                    }
                }

                &__button
                {   
                    margin-top: 1rem;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &__holder
                    {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        button
                        {
                            background: none;
                            font-size: 1rem;
                            color: $red;
                            border: 2px solid $red;
                            padding-top: 1rem;
                            padding-bottom: 1rem;
                            width: 100%;
                            border-radius: 5px;
                            transition: all 0.4s ease-in-out;
                        }

                        button:hover
                        {
                            cursor: pointer;
                            border: 2px solid $redHover;
                            color: $redHover;
                            transform: translateY(-1px);
                            box-shadow: 0 1px 3px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.24);
                        }

                    }



                }
            }



        }
    }

    @media (max-width: 1024px)
    {
        .contact
        {
            &__content
            {
                
                width: 90%;

                &__form
                {
                    width: 90%;
                }

                

                            
            }


        }
    }
    @media (max-width: 769px) {
    .contact__content{
        margin-top: 4.768em;
    }
  }


</style>